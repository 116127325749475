//
// Theme style
//

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "./core/layout/base/layout";
@import "layout/layout";

// Animation
.rotate-90-0 {
    -webkit-animation: rotate-0-cw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: rotate-0-cw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.rotate-0-90 {
    -webkit-animation: rotate-90-cw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: rotate-90-cw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes rotate-90-cw {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}
@keyframes rotate-90-cw {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}

@-webkit-keyframes rotate-0-cw {
    0% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}
@keyframes rotate-0-cw {
    0% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}

.fade-in {
    -webkit-animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
